import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import { SessionHandlerContext } from 'context/RfytSessionContext';
import { IAddCreditsResponse } from 'contracts/requests/IAddCreditsRequest';
import { RetrievePaymentDetailsDataSerializer } from 'contracts/spotdif/RetrievePaymentDetailsData';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo } from 'react';
import { useAddContactToCreditMutation } from 'redux/services/spotdif/creditAndBilling';

import Call from 'assets/svg/call.svg';
import Chat from 'assets/svg/chat.svg';
import Hotkey from 'assets/svg/hotkey.svg';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import classNames from 'classnames';
import useGlobalMinimumTopupLeads from 'hooks/useGlobalMinimumTopupLeads';
const BuyContactCredits = () => {
    const VAT_AMOUNT = parseFloat(process.env.REACT_APP_VAT_AMOUNT) || 0.2;
    const [addContactToCredit] = useAddContactToCreditMutation();
    const { user } = useAuthentication();
    const { setSessionId, setPaymentMethod } = useContext(SessionHandlerContext);
    const { minimumLeadConfigAsPerAdmin } = useGlobalMinimumTopupLeads();

    const formik = useFormik({
        initialValues: {
            liveChatLeads: null,
            inboundCallLeads: null,
            hotkeys: null,
        },
        onSubmit: (values, { resetForm }) => {
            const minLeads = minimumLeadConfigAsPerAdmin ?? 5
            if (
                (values.liveChatLeads && values.liveChatLeads < minLeads) ||
                (values.inboundCallLeads && values.inboundCallLeads < minLeads) ||
                (values.hotkeys && values.hotkeys < minLeads)
            ) {
                enqueueSnackbar(`Minimum top-up amount for each credit type is ${minLeads}.`, { variant: 'error' });
            } else {
                addContactToCredit({
                    liveChatLeads: values.liveChatLeads || null,
                    inboundCallLeads: values.inboundCallLeads || null,
                    hotkeys: values.hotkeys || null,
                })
                    .unwrap()
                    .then((res: IAddCreditsResponse) => {
                        const response = RetrievePaymentDetailsDataSerializer.parse(res.data);
                        if (response.needsManualPayment) {
                            // handleOpenCreditModal();
                        }
                        if (+res?.data?.status === 302 && res?.data?.url) {
                            window.open(res?.data?.url);
                            return;
                        }

                        if (res?.data?.sessionID === undefined) {
                            setSessionId(null);
                        } else {
                            setSessionId(res.data.sessionID);
                            setPaymentMethod(res.data.paymentMethods);
                        }
                        resetForm();
                        console.log(res, '>>>> response');
                    })
                    .catch((err) => {
                        console.log(err, '>>>>.error');
                        enqueueSnackbar(
                            err?.data?.data?.errors[0]?.message ?? err?.data?.error?.message ?? 'Something went Wrong',
                            { variant: 'error' },
                        );
                    });
            }
        },
    });

    const { handleBlur, setFieldValue, values } = formik;

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (/^[1-9]\d*$/.test(value) || value === '') {
            setFieldValue(name, value ? +value : '');
        }
    }, []);

    const totalPrice = useMemo(() => {
        let price = user?.businessIndustry?.contactOptions?.reduce((acc, curr) => {
            if (curr.isActive) {
                return acc + values?.[curr.optionSlug] * curr.optionPrice;
            }
            return acc;
        }, 0);

        return price;
    }, [values]);
    // console.log(totalPrice, '>>>> total price');
    return (
        <>
            <div className="available-credit-details">
                <div className="contact-credit">
                    <div className="heading_contact">
                        Chat Leads <i>{user?.contactCredits?.liveChatLeads}</i>
                    </div>
                    <div>
                        <img src={Call} alt="" />
                    </div>
                </div>
                <div className="contact-credit">
                    <div className="heading_contact">
                        {' '}
                        Call Leads <i>{user?.contactCredits?.inboundCallLeads}</i>
                    </div>
                    <div>
                        <img src={Chat} alt="" />
                    </div>
                </div>
                <div className="contact-credit">
                    <div className="heading_contact">
                        {' '}
                        Hotkeys <i>{user?.contactCredits?.hotkeys}</i>
                    </div>
                    <div>
                        <img src={Hotkey} alt="" />
                    </div>
                </div>
            </div>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="buy-contact-credits">
                        <div>
                            {user?.businessIndustry?.contactOptions?.map((contact, index) => {
                                if (contact.isActive) {
                                    return (
                                        <MDInput
                                            onBlur={handleBlur}
                                            name={contact.optionSlug}
                                            type="text"
                                            label={contact.optionName}
                                            placeholder="Enter amount"
                                            onChange={handleChange}
                                            value={values?.[contact?.optionSlug] ?? ''}
                                            key={index}
                                        />
                                    );
                                } else {
                                    return <React.Fragment key={index}></React.Fragment>;
                                }
                            })}
                        </div>
                        <div>
                            <div>
                                <h6>Order Total:</h6>
                                <ul>
                                    {user?.businessIndustry?.contactOptions?.map((contact, index) => {
                                        if (contact.isActive) {
                                            return (
                                                <li>
                                                    <span>{contact.optionName} : </span>
                                                    <span>
                                                        {values?.[contact.optionSlug]
                                                            ? values?.[contact.optionSlug]
                                                            : 0}{' '}
                                                        * {contact.optionPrice}
                                                    </span>
                                                </li>
                                            );
                                        } else {
                                            return <React.Fragment key={index}></React.Fragment>;
                                        }
                                    })}
                                    {/* <li>
                                    <span>Live Chat Leads : </span>
                                    <span>
                                        {values.liveChatLeads ? values.liveChatLeads : 0} *{' '}
                                        {getContactOptionDetails('liveChatLeads').optionPrice}
                                    </span>
                                </li>
                                <li>
                                    <span>Inbound Chat Leads : </span>
                                    <span>
                                        {values.inboundCallLeads ? values.inboundCallLeads : 0} *{' '}
                                        {getContactOptionDetails('inboundCallLeads').optionPrice}
                                    </span>
                                </li>
                                <li>
                                    <span>Hotkeys : </span>
                                    <span>
                                        {values.hotkeys ? values.hotkeys : 0} *{' '}
                                        {getContactOptionDetails('hotkeys').optionPrice}
                                    </span>
                                </li> */}
                                </ul>
                                <ul className="bottom_list_ul">
                                    {user?.currency !== 'EUR' && (
                                        <li>
                                            <span>Total (exc. VAT) : </span>
                                            <span>
                                                <CurrencySymbol currencyCode={user?.currency} />
                                                {totalPrice.toFixed(2)}
                                            </span>
                                        </li>
                                    )}
                                    {user?.currency === 'EUR' ? (
                                        <li>
                                            <span>Total to pay : </span>
                                            <span>
                                                <CurrencySymbol currencyCode={user?.currency} />
                                                {totalPrice.toFixed(2)}
                                            </span>
                                        </li>
                                    ) : (
                                        <li>
                                            <span>Total to pay (inc. VAT) </span>
                                            <span className="total_amount">
                                                <CurrencySymbol currencyCode={user?.currency} />
                                                {`${(Number(totalPrice) * (1 + Number(VAT_AMOUNT))).toFixed(2)}`}
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="confirm_order_btn">
                                <MDButton
                                    className={classNames('button', {
                                        'btn-disabled': totalPrice === 0,
                                    })}
                                    type="submit"
                                    disabled={totalPrice === 0}
                                    // isLoading={isUpdatingUserProfile}
                                >
                                    Confirm Order
                                </MDButton>
                            </div>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
        </>
    );
};

export default BuyContactCredits;
