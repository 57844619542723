// import { IFreeCreditsRequest, IFreeCreditsResponse } from "contracts/requests/IFreeCreditsRequest";
import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import {
    ILeadsListForAdmintoExportFileResponse,
    ILeadsListForAdmintoExportMessage,
} from 'contracts/requests/ILeadsListForAdmintoExportFileResponse';
import { ILeadsLIstQuery, ILeadsListResponse } from 'contracts/requests/ILeadsListResponse';
// import Partial
import { IUserPreferenceRequest, IUserPreferenceResponse } from 'contracts/requests/IUserPreferenceRequest';
import { IAdminLeadsStats } from 'contracts/spotdif/AdminLeadsStatsResponse';
import { LeadsBidData } from 'contracts/spotdif/LeadsBidData';
import { TablePreferenceDataObject } from 'contracts/spotdif/TablePreferenceDataObject';
import {
    TAG_CHANGE_LEAD,
    TAG_CHAT_ROOM_DETAILS,
    TAG_GET_USER_PROFILE,
    TAG_LEAD_PREFERENCE,
    TAG_REPORT_LEAD,
    TAG_USER_PROFILE,
} from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const leadsApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllLeads: builder.query<ILeadsListResponse, ILeadsLIstQuery>({
            providesTags: [TAG_REPORT_LEAD, TAG_CHANGE_LEAD],
            query: (args) => {
                return {
                    // url: `/leads/allLeads?page=${args.page}&perPae=${args.perPage}&search=${args.search}`,
                    // page=2&perPage=10&sortingOrder="desc
                    url: `/leads/allLeads${
                        args.clientId
                            ? `/${args.clientId}?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}&sortingOrder=${args?.sortingOrder}&status=${args?.leadsStatus}&industryId=${args?.industry}&accountManagerId=${args?.accountManagerId}`
                            : `?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}&sortingOrder=${args?.sortingOrder}&status=${args?.leadsStatus}&industryId=${args?.industry}&accountManagerId=${args?.accountManagerId}&leadType=${args?.leadType}`
                    }`,
                    method: 'get',
                };
            },
        }),
        getClientAllLeads: builder.query<ILeadsListResponse, ILeadsLIstQuery>({
            providesTags: (result, error, arg, meta) => {
                return [
                    TAG_REPORT_LEAD,
                    TAG_CHANGE_LEAD,
                    ...result?.data?.map((lead) => ({
                        type: TAG_CHANGE_LEAD,
                        id: lead._id,
                    })),
                ];
            },

            query: (args) => {
                return {
                    // url: `/leads/allLeads?page=${args.page}&perPae=${args.perPage}&search=${args.search}`,
                    url: `/leads${
                        args.clientId
                            ? '/' + args.clientId
                            : `?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}&sortingOrder=${args?.sortingOrder}&status=${args?.leadsStatus}&leadType=${args?.leadType}`
                    }`,
                    method: 'get',
                };
            },
        }),
        reportLeadSale: builder.mutation<
            void,
            Partial<{
                leadId: string;
                soldAt: number | null;
            }>
        >({
            invalidatesTags: (result, error, arg, meta) => {
                return [{ type: TAG_CHANGE_LEAD, id: arg.leadId }];
            },
            query: ({ leadId, soldAt }) => {
                return {
                    url: `leads/${leadId}/report-sale`,
                    method: 'post',
                    body: {
                        soldAt: soldAt?.toFixed(2),
                    },
                };
            },
        }),
        reportLead: builder.mutation<
            {
                data: LeadsBidData;
            },
            Partial<{
                leadId: string;
                status: string;
                clientNotes: String;
                invalidLeadReason: string;
                contactStatus: string;
            }> &
                Pick<{ leadId: string; status: string; clientNotes: String; invalidLeadReason: string }, 'leadId'>
        >({
            invalidatesTags: ({ data }, error, arg, meta) => {
                return [
                    { type: TAG_CHANGE_LEAD, id: arg.leadId },
                    {
                        type: TAG_CHAT_ROOM_DETAILS,
                        id: data.leads.leadid,
                    },
                ];
            },
            query: ({ leadId, ...patch }) => {
                return {
                    url: `leads/update/${leadId}`,
                    // method: 'PATCH',
                    method: 'post',
                    body: patch,
                };
            },
        }),
        getLeadPreference: builder.query<TablePreferenceDataObject, void>({
            providesTags: [
                TAG_LEAD_PREFERENCE,
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],

            query: () => {
                return {
                    url: '/leads/leads-Preference',
                    method: 'get',
                };
            },
            transformResponse: (response: IUserPreferenceResponse) => {
                return response.data;
            },
        }),
        getAdminLeadsStats: builder.query<IAdminLeadsStats, void>({
            providesTags: [TAG_CHANGE_LEAD, TAG_REPORT_LEAD],
            query: () => {
                return {
                    url: 'leads/stat',
                    method: 'get',
                };
            },
        }),
        leadPreferenceUpdate: builder.mutation<IUserPreferenceResponse | IApiErrorResponse, IUserPreferenceRequest>({
            invalidatesTags: [TAG_LEAD_PREFERENCE],

            query: (body) => {
                return {
                    url: 'leads/leads-Preference',
                    method: 'post',
                    body,
                };
            },
        }),
        exportLeads: builder.mutation<ILeadsListForAdmintoExportMessage, string | void>({
            query: (filterUrl = '') => {
                return {
                    url: `leads/export-csv-file-admin-leads${filterUrl}`,
                    method: 'get',
                };
            },
        }),
        exportClientCsv: builder.mutation<LeadsBidData[], string | void>({
            query: (filterUrl = '') => {
                return {
                    url: `leads/export-csv-file-user-leads${filterUrl}`,
                    method: 'get',
                };
            },
            transformResponse: (response: ILeadsListForAdmintoExportFileResponse) => {
                return response?.data;
            },
        }),
    }),
});

export const {
    useGetAllLeadsQuery,
    useReportLeadMutation,
    useGetLeadPreferenceQuery,
    useLeadPreferenceUpdateMutation,
    useExportLeadsMutation,
    useGetClientAllLeadsQuery,
    useReportLeadSaleMutation,
    useExportClientCsvMutation,
    useGetAdminLeadsStatsQuery,
} = leadsApi;

// export function updateLeadPrefernces(columns) {
//     leadsApi.util.updateQueryData('getLeadPreferene', undefined, (draft) => {

//         draft.columns = columns;

//         return draft;
//     });;
// }
